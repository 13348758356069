import { Individual, makeIndividual } from '@/types/people'
import { dateToString } from '@/filters/texts'

class PublicationTitle {
  constructor(
    public id: number,
    public name: string
  ) {
  }
}

export function makePublicationTitle(jsonData: any = null): PublicationTitle {
  if (jsonData === null) {
    jsonData = {}
  }
  return new PublicationTitle(
    jsonData.id || 0,
    jsonData.name || ''
  )
}

class PublicationType {
  constructor(
    public id: number,
    public name: string,
    public publicationTitle: PublicationTitle,
    public price: number,
    public sendingPrice: number,
    public isPublication: boolean,
    public hasArticles: boolean,
    public hasAuthors: boolean,
    public next: number
  ) {
  }
}

export function makePublicationType(jsonData: any = null): PublicationType {
  if (jsonData === null) {
    jsonData = {}
  }
  return new PublicationType(
    jsonData.id || 0,
    jsonData.name || '',
    makePublicationTitle(jsonData.publication_title),
    jsonData.price,
    jsonData.sendsending_price,
    !!jsonData.is_publication,
    !!jsonData.has_articles,
    !!jsonData.has_authors,
    jsonData.next || 0
  )
}

class PublicationAuthor {
  constructor(
    public id: number,
    public individual: Individual,
    public createdOn: Date
  ) {
  }
}

export function makePublicationAuthor(jsonData: any = null): PublicationAuthor {
  if (jsonData === null) {
    jsonData = {}
  }
  return new PublicationAuthor(
    jsonData.id || 0,
    makeIndividual(jsonData.individual),
    jsonData.created_on
  )
}

class PublicationKeyword {
  constructor(
    public id: number,
    public keyword: string
  ) {
  }
}

export function makePublicationKeyword(jsonData: any = null): PublicationKeyword {
  if (jsonData === null) {
    jsonData = {}
  }
  return new PublicationKeyword(
    jsonData.id || 0,
    jsonData.keyword || ''
  )
}

class PublicationArticle {
  constructor(
    public id: number,
    public title: string,
    public comments: string,
    public notes: string,
    public authors: PublicationAuthor[],
    public keywords: PublicationKeyword[]
  ) {
  }
}

export function makePublicationArticle(jsonData: any = null): PublicationArticle {
  if (jsonData === null) {
    jsonData = {}
  }
  let keywords = []
  let authors = []
  if (jsonData.keywords) {
    keywords = jsonData.keywords.map(makePublicationKeyword)
  }
  if (jsonData.authors) {
    authors = jsonData.authors.map(makePublicationAuthor)
  }
  return new PublicationArticle(
    jsonData.id || 0,
    jsonData.title || '',
    jsonData.comments || '',
    jsonData.notes || '',
    authors,
    keywords
  )
}

class Publication {
  constructor(
    public id: number,
    public publicationType: PublicationType,
    public number: number|null,
    public title: string,
    public publishedOn: Date|null,
    public price: number,
    public sendingPrice: number,
    public authorPrice: number,
    public fabPrice: number,
    public isAvailable: boolean,
    public pagesCount: number,
    public notes: string,
    public articles: PublicationArticle[],
    public authors: PublicationAuthor[]
  ) {
  }

  public fullName(): string {
    let words: string[] = [this.publicationType.name]
    if (this.number) {
      words.push('N°' + this.number)
    }
    if (this.title) {
      words.push(this.title)
    }
    if (this.publishedOn) {
      words.push(dateToString(this.publishedOn, 'LL'))
    }
    return words.join(' - ')
  }
}

export function makePublication(jsonData: any = null): Publication {
  if (jsonData === null) {
    jsonData = {}
  }
  let articles = []
  if (jsonData.articles) {
    articles = jsonData.articles.map(makePublicationArticle)
  }
  let authors = []
  if (jsonData.authors) {
    authors = jsonData.authors.map(makePublicationAuthor)
  }
  return new Publication(
    jsonData.id || 0,
    makePublicationType(jsonData.publication_type),
    jsonData.number || null,
    jsonData.title || '',
    jsonData.published_on || null,
    jsonData.price || 0,
    jsonData.sending_price || 0,
    jsonData.author_price || 0,
    jsonData.fab_price || 0,
    !!jsonData.is_available,
    jsonData.pages_count || 0,
    jsonData.notes || '',
    articles,
    authors
  )
}
