<template>
  <div class="publications">
    <page-header :title="title" icon="fa fa-book-open-reader"></page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <b-row>
      <b-col cols="2">
        <b-form-group label-for="publicationType" label="Type">
          <b-select
            v-model="publicationType"
            :disabled="!editMode"
          >
            <b-select-option v-for="elt of publicationTypes" :key="elt.id" :value="elt">
              {{ elt.name }}
            </b-select-option>
          </b-select>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label-for="publicationNumber" label="Numéro">
          <b-input
            id="publicationNumber"
            v-model="publicationNumber"
            type="number" step="1" min="0"
            :disabled="!editMode"
          >
          </b-input>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label-for="publicationTitle" label="Titre">
          <b-input id="publicationTitle" v-model="publicationTitle" type="text" :disabled="!editMode">
          </b-input>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label-for="publishedOn" label="Date de publication">
          <b-input id="publishedOn" v-model="publishedOn" type="date" :disabled="!editMode">
          </b-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="2">
        <b-form-group label-for="price" label="Prix">
          <decimal-input v-model="price" :disabled="!editMode"></decimal-input>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label-for="sendingPrice" label="Prix d'envoi">
          <decimal-input v-model="sendingPrice" :disabled="!editMode"></decimal-input>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label-for="fabPrice" label="Prix de revient">
          <decimal-input v-model="fabPrice" :disabled="!editMode"></decimal-input>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label-for="authorPrice" label="Prix auteur">
          <decimal-input v-model="authorPrice" :disabled="!editMode"></decimal-input>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label-for="pagesCount" label="Nb de pages">
          <number-input v-model="pagesCount" step="1" :disabled="!editMode"></number-input>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label-for="isAvailable" label="Disponible">
          <b-form-checkbox v-model="isAvailable" :disabled="!editMode"></b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-form-group label-for="notes" label="Notes">
          <b-textarea id="notes" v-model="notes" :disabled="!editMode">
          </b-textarea>
        </b-form-group>
      </b-col>
    </b-row>
    <div v-if="hasAuthors">
      <b-col>
        <b-form-group
          label-for="authors"
          :label="(authors.length > 1) ? 'Auteurs' : 'Auteur'"
        >
          <div v-if="!editMode && authors.length === 0" class="empty-text">
            aucun
          </div>
          <b-row
            v-for="author of authors"
            :key="author.id"
          >
            <b-col cols="10">
              <b-input
                :value="author.individual.firstAndLastName()"
                type="text"
                disabled
              >
              </b-input>
            </b-col>
            <b-col cols="2" class="small-text">
              <a href @click.prevent="deleteAuthor(author)" v-if="editMode">
                <i class="fa fa-trash"></i>
              </a>
            </b-col>
          </b-row>
          <div class="small-text" v-if="editMode">
            <a href @click.prevent="addAuthor()">
              <i class="fa fa-plus"></i> Ajouter
            </a>
          </div>
        </b-form-group>
      </b-col>
    </div>
    <div v-if="hasArticles">
      <div class="articles-header">
        <b-row>
          <b-col>
            <b><counter-label :counter="articles.length" label="article"></counter-label></b>
            &nbsp;
            <a href @click.prevent="addArticle()" class="btn btn-secondary" v-if="editMode">
              <i class="fa fa-plus"></i> Ajouter
            </a>
          </b-col>
          <b-col cols="6"></b-col>
        </b-row>
      </div>
      <div v-for="article of articles" :key="article.id" class="article-line">
        <b-row>
          <b-col cols="6">
            <b-form-group :label-for="'article' + article.id" label="Titre">
              <b-input :id="'article' + article.id" v-model="article.title" type="text" :disabled="!editMode">
              </b-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              :label-for="'author' + article.id"
              :label="(article.authors.length > 1) ? 'Auteurs' : 'Auteur'"
            >
              <div v-if="!editMode && article.authors.length === 0" class="empty-text">
                aucun
              </div>
              <b-row
                v-for="author of article.authors"
                :key="author.id"
              >
                <b-col cols="10">
                  <b-input
                    :value="author.individual.firstAndLastName()"
                    type="text"
                    disabled
                  >
                  </b-input>
                </b-col>
                <b-col cols="2" class="small-text">
                  <a href @click.prevent="deleteArticleAuthor(article, author)" v-if="editMode">
                    <i class="fa fa-trash"></i>
                  </a>
                </b-col>
              </b-row>
              <div class="small-text" v-if="editMode">
                <a href @click.prevent="addArticleAuthor(article)">
                  <i class="fa fa-plus"></i> Ajouter
                </a>
              </div>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label-for="'keywords' + article.id" label="Mots-clef">
              <div v-if="!editMode && article.keywords.length === 0" class="empty-text">
                aucun
              </div>
              <b-row v-for="keyword of article.keywords" :key="keyword.id">
                <b-col cols="10">
                  <b-input v-model="keyword.keyword" type="text" :disabled="!editMode">
                  </b-input>
                </b-col>
                <b-col cols="2" class="small-text" v-if="editMode">
                  <a href @click.prevent="deleteArticleKeyword(article, keyword)">
                    <i class="fa fa-trash"></i>
                  </a>
                </b-col>
              </b-row>
              <div class="small-text" v-if="editMode">
                <a href @click.prevent="addArticleKeyword(article)">
                  <i class="fa fa-plus"></i> Ajouter
                </a>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group :label-for="'article-notes' + article.id" label="Notes">
              <b-textarea :id="'article-notes' + article.id" v-model="article.notes" :disabled="!editMode">
              </b-textarea>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group :label-for="'article-comments' + article.id" label="Commentaires">
              <b-textarea :id="'article-comments' + article.id" v-model="article.comments" :disabled="!editMode">
              </b-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-right" v-if="editMode">
            <a href @click.prevent="deleteArticle(article)" class="btn btn-secondary">
              <i class="fa fa-trash"></i> Supprimer l'article
            </a>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-row>
      <b-col class="text-right">
        <a href @click.prevent="onEdit()" class="btn btn-secondary" v-if="!editMode">
          <i class="fa fa-edit"></i> Modifier
        </a>
        <a href @click.prevent="onCancel()" class="btn btn-secondary" v-if="editMode && publication">
          <i class="fa fa-close"></i> Annuler
        </a>
        &nbsp;
        <a href @click.prevent="onSave()" class="btn btn-primary" v-if="editMode">
          <i class="fa fa-save"></i> Enregistrer
        </a>
      </b-col>
    </b-row>
    <add-publication-author
      modal-id="bv-modal-add-author"
      @done="onAuthorAdded($event)">
    </add-publication-author>
  </div>
</template>

<script>
// @ is an alias to /src
import router from '@/router'
import store from '@/store'
import { mapActions, mapMutations } from 'vuex'
import DecimalInput from '@/components/Controls/DecimalInput.vue'
import NumberInput from '@/components/Controls/NumberInput.vue'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import CounterLabel from '@/components/Controls/CounterLabel'
import AddPublicationAuthor from '@/components/Publications/AddPublicationAuthor.vue'
import { dateToString } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makePublication, makePublicationType, makePublicationArticle } from '@/types/publications'

export default {
  name: 'publications-edit',
  mixins: [BackendMixin],
  components: {
    NumberInput,
    DecimalInput,
    AddPublicationAuthor,
    CounterLabel,
    LoadingGif,
    PageHeader,
  },
  props: {
    publicationId: [String, Number],
  },
  data() {
    return {
      loadingName: 'publications-edit',
      publicationType: null,
      publicationTypes: [],
      publishedOn: '',
      publicationTitle: '',
      publicationNumber: null,
      articles: [],
      publication: null,
      newArticlesCounter: 0,
      newKeywordsCounter: 0,
      newAuthorsCounter: 0,
      selectedArticle: null,
      price: null,
      sendingPrice: null,
      fabPrice: null,
      authorPrice: null,
      isAvailable: false,
      pagesCount: 0,
      notes: '',
      authors: [],
      editMode: false,
    }
  },
  watch: {
    publicationId: function() {
      this.loadPublication()
    },
    publicationType: function() {
      if (this.publicationType && !this.publicationId) {
        this.publicationNumber = this.publicationType.next
        this.price = this.publicationType.price
        this.sendingPrice = this.publicationType.sendingPrice
      }
    },
  },
  computed: {
    title() {
      return store.getters.config.publicationTitle
    },
    hasAuthors() {
      return this.publicationType && this.publicationType.hasAuthors
    },
    hasArticles() {
      return this.publicationType && this.publicationType.hasArticles
    },
  },
  mounted() {
    this.loadPublication()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess', 'addWarning']),
    ...mapMutations(['startLoading', 'endLoading']),
    async init() {
      if (this.publication) {
        this.publicationType = this.publication.publicationType
        this.publicationTypes = [this.publicationType]
        if (this.publication.publishedOn) {
          this.publishedOn = dateToString(this.publication.publishedOn, 'YYYY-MM-DD')
        } else {
          this.publishedOn = ''
        }
        this.publicationNumber = this.publication.number
        this.articles = [].concat(this.publication.articles)
        this.authors = [].concat(this.publication.authors)
        this.publicationTitle = this.publication.title
        this.price = this.publication.price
        this.sendingPrice = this.publication.sendingPrice
        this.sendingPrice = this.publication.sendingPrice
        this.fabPrice = this.publication.fabPrice
        this.authorPrice = this.publication.authorPrice
        this.isAvailable = this.publication.isAvailable
        this.pagesCount = this.publication.pagesCount
        this.notes = this.publication.notes
        this.editMode = false
      } else {
        this.publishedOn = ''
        this.publicationTitle = ''
        this.publicationNumber = null
        if (this.publicationTypes.length === 1) {
          this.publicationType = this.publicationTypes[0]
        }
        this.price = null
        this.sendingPrice = null
        this.fabPrice = null
        this.authorPrice = null
        this.isAvailable = true
        this.pagesCount = 0
        this.notes = ''
        this.editMode = true
      }
    },
    async loadPublicationTypes() {
      this.startLoading(this.loadingName)
      try {
        let url = '/publications/api/publication-types/'
        const backendApi = new BackendApi('get', url)
        const resp = await backendApi.callApi()
        this.publicationTypes = resp.data.map(makePublicationType).filter(elt => elt.isPublication)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async loadPublication() {
      if (this.publicationId) {
        this.startLoading(this.loadingName)
        try {
          let url = '/publications/api/publications/' + this.publicationId + '/'
          const backendApi = new BackendApi('get', url)
          const resp = await backendApi.callApi()
          this.publication = makePublication(resp.data)
          await this.init()
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
        this.endLoading(this.loadingName)
      } else {
        await this.loadPublicationTypes()
        await this.init()
      }
    },
    async savePublication() {
      this.startLoading(this.loadingName)
      try {
        let url = '/publications/api/publications/'
        const backendApi = new BackendApi('get', url)
        const resp = await backendApi.callApi()
        this.publications = resp.data.map(elt => makePublication(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    addArticle() {
      this.newArticlesCounter = this.newArticlesCounter - 1
      this.articles = this.articles.concat(
        [
          makePublicationArticle(
            { id: this.newArticlesCounter, }
          )
        ]
      )
    },
    deleteArticle(article) {
      const index = this.articles.map(elt => elt.id).indexOf(article.id)
      if (index >= 0) {
        this.articles.splice(index, 1)
        this.articles = this.articles.concat([])
      }
    },
    deleteArticleAuthor(article, author) {
      const index = article.authors.map(elt => elt.id).indexOf(author.id)
      if (index >= 0) {
        article.authors.splice(index, 1)
        article.authors = article.authors.concat([])
      }
    },
    deleteAuthor(author) {
      const index = this.authors.map(elt => elt.id).indexOf(author.id)
      if (index >= 0) {
        this.authors.splice(index, 1)
        this.authors = this.authors.concat([])
      }
    },
    deleteArticleKeyword(article, keyword) {
      const index = article.keywords.map(elt => elt.id).indexOf(keyword.id)
      if (index >= 0) {
        article.keywords.splice(index, 1)
        article.keywords = article.keywords.concat([])
      }
    },
    addArticleKeyword(article) {
      this.newKeywordsCounter = this.newKeywordsCounter - 1
      article.keywords.push({ id: this.newKeywordsCounter, })
    },
    addArticleAuthor(article) {
      this.selectedArticle = article
      this.$bvModal.show('bv-modal-add-author')
    },
    addAuthor() {
      this.selectedArticle = null
      this.$bvModal.show('bv-modal-add-author')
    },
    onAuthorAdded(event) {
      this.newAuthorsCounter = this.newAuthorsCounter - 1
      const individual = event.individual
      const authorsList = this.selectedArticle ? this.selectedArticle.authors : this.authors
      const exists = authorsList.map(
        elt => elt.individual.id
      ).indexOf(individual.id) >= 0
      if (!exists) {
        authorsList.push(
          { id: this.newAuthorsCounter, individual: individual, }
        )
      } else {
        this.addWarning('' + individual.firstAndLastName() + ' est déjà listé comme auteur de cet article')
      }
    },
    onEdit() {
      this.editMode = true
    },
    onCancel() {
      this.editMode = false
    },
    async onSave() {
      this.startLoading(this.loadingName)
      let url = '/publications/api/publications/'
      let method = 'post'
      if (this.publication) {
        url += this.publicationId + '/'
        method = 'patch'
      }
      const authors = this.hasAuthors ? this.authors : []
      const articles = this.hasArticles ? this.articles : []
      const data = {
        'publication_type': this.publicationType.id,
        'title': this.publicationTitle,
        'number': this.publicationNumber,
        'published_on': this.publishedOn ? dateToString(this.publishedOn, 'YYYY-MM-DD') : null,
        'authors_individuals': authors.map(elt => elt.individual.id),
        'articles': articles.map(
          artData => {
            return {
              'title': artData.title,
              'keywords': artData.keywords.map(keyword => keyword.keyword).filter(word => !!word),
              'authors_individuals': artData.authors.map(elt => elt.individual.id),
              'notes': artData.notes,
              'comments': artData.comments,
            }
          }
        ),
        'price': this.price,
        'sending_price': this.sendingPrice,
        'author_price': this.authorPrice,
        'is_available': this.isAvailable,
        'fab_price': this.fabPrice,
        'notes': this.notes,
      }
      const backendApi = new BackendApi(method, url)
      try {
        const resp = await backendApi.callApi(data)
        const publication = makePublication(resp.data)
        if (this.publicationId) {
          this.publication = publication
          await this.init()
        } else {
          await router.push(
            { name: 'publications-edit', params: { 'publicationId': publication.id, }, }
          )
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
  },
}
</script>

<style scoped lang="less">
.articles-header {
  padding:  10px;
  background: #ccc;
}
.article-line {
  margin-bottom: 20px;
  padding: 10px;
  background: #eee;
}
</style>
